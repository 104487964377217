// FilterSearch helper functions and data

import { FilterSearchFormValues, Options } from "./FilterSearch.types";

export const options: Options[] = [
  {
    id: 1,
    title: "Especie",
    name: "species",
    filters: ["Todos", "Perros", "Gatos", "Compañeros inseparables", "Otros"]
  },
  {
    id: 2,
    title: "Edad",
    name: "age",
    filters: ["Todos", "Cachorro", "Adulto", "Senior"]
  },
  {
    id: 3,
    title: "Sexo",
    name: "sex",
    filters: ["Todos", "Hembra", "Macho"]
  },
  {
    id: 4,
    title: "Tamaño",
    name: "size",
    filters: ["Todos", "Pequeño", "Mediano", "Grande"]
  }
];

export const getDefaultFilterValues = (
  filterProduct: FilterSearchFormValues,
  isMobile?: boolean
) => {
  const { age, species, sex, size } = filterProduct;

  return {
    defaultValues: {
      age: isMobile ? "" : age,
      species: isMobile ? "" : species,
      sex: isMobile ? "" : sex,
      size: isMobile ? "" : size
    }
  };
};

export const categories = [
  { id: 1515, name: "Perros" },
  { id: 1516, name: "Gatos" },
  { id: 1523, name: "Compañeros Inseparables" },
  { id: 1530, name: "Otros" }
];
