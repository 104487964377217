import CONSTANTS from "config/constants";
import styled from "styled-components";

import { FilterSearchStyledProps as Props } from "./FilterSearch.types";
import { DropdownOverlayStyledProps as DropdownProps } from "./FilterSearch.types";

const { tablet } = CONSTANTS.BREAKPOINTS;

const FilterSearchStyled = styled.div<Props>`
  position: relative;
  z-index: 1;

  .FilterSearch {
    &__form {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      background-color: var(--palette-white);
      margin-top: 2.8rem;
      padding: 1.2rem 2.4rem;
      padding-right: 1.2rem;
      border-radius: 4rem;
      max-width: 69.4rem;
      gap: 1.2rem;
      align-items: center;

      @media (max-width: ${tablet}px) {
        grid-template-columns: 1fr 1fr;
        padding: 1.6rem 1rem;
      }
    }

    &__item {
      border-right: 0.1rem solid var(--palette-gray-s0-l85);

      @media (max-width: ${tablet}px) {
        border-right: unset;
      }
    }

    &__title {
      font-weight: 600;
      padding-left: 1.2rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__value {
      max-width: 6rem;
      overflow: hidden;
      text-overflow: ellipsis;

      @media (max-width: ${tablet}px) {
        font-weight: 600;

        &--selected {
          color: var(--palette-primary);
        }
      }
    }

    &__dropdown {
    }

    &__button {
      display: flex;
      align-items: center;
      padding: 0.8rem 1.2rem;
      border-radius: 0.8rem;
      justify-content: space-between;
      cursor: pointer;
      background-color: transparent;
      border: 0;
      width: 100%;
      height: 4.4rem;

      &:hover:not([disabled]) {
        border: 0;
        background-color: transparent;
      }

      @media (max-width: ${tablet}px) {
        background-color: var(--palette-gray-s0-l98);
        border-radius: 1rem;
        height: unset;
      }
    }

    &__submit {
      cursor: pointer;
      font-size: 1.6rem;
      font-weight: 800;
      border-radius: 4rem;
      height: 4.4rem;

      @media (max-width: ${tablet}px) {
        grid-column: 1/3;
      }

      svg {
        margin-right: 1.2rem;
      }

      @media (max-width: ${tablet}px) {
        height: 5.6rem;
      }
    }
  }
`;

export const DropdownOverlayStyled = styled.div<DropdownProps>`
  padding: 3rem 1.8rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 31.8rem;

  @media (max-width: ${tablet}px) {
    position: fixed;
  }

  .DropdownOverlay {
    &__title {
      font-size: 2rem;
      color: var(--palette-gray-s210-l4-a52);
      border-bottom: 0.1rem solid var(--palette-gray-s0-l95);
      padding-bottom: 0.4rem;
    }

    &__item {
      border-bottom: 0.1rem solid var(--palette-gray-s0-l95);
      margin-top: 0.8rem;
      margin-bottom: 0.6rem;
      display: flex;
      overflow: hidden;

      input[type="radio"] {
        appearance: none;
        margin: 0;

        &:checked ~ .DropdownOverlay__label {
          color: var(--palette-red-s85-l45);
          background-color: var(--palette-purple-s100-l99);
        }
      }
    }

    &__label {
      position: relative;
      font-weight: 600;
      font-size: 1.6rem;
      color: var(--palette-blue-s51-l16);
      border-radius: 0.8rem;
      width: 100%;
      padding: 1.2rem 0.8rem;
      cursor: pointer;
    }

    &__options {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      &__link {
        font-size: 1.6rem;
      }

      &__confirm {
        border-radius: 4rem;
        font-size: 1.6rem;
        padding: 1.2rem 3.2rem;
      }
    }
  }
`;

export default FilterSearchStyled;
