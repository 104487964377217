import React, { useState, useEffect } from "react";
import { Dropdown } from "artisn-ui-react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";

import Styles, { DropdownOverlayStyled } from "./FilterSearch.styles";
import { FilterFormValues, FilterSearchFormValues } from "./FilterSearch.types";
import { FilterSearchQueryParams } from "./FilterSearch.types";
import { FilterSearchProps as Props } from "./FilterSearch.types";
import Button from "../Button/Button";
import useOutsideClick from "hooks/useOutsideClick";
import { categories, getDefaultFilterValues } from "./FilterSearch.helpers";
import { options } from "./FilterSearch.helpers";
import CONSTANTS from "config/constants";
import useWindowSize from "hooks/useWindowSize";
import { getProductTags } from "components/product/Product/Product.helpers";
import { filterDefault } from "contexts/products/products.context.helpers";

import SearchVG from "../../../../public/assets/images/search.svg";
import ArrowSVG from "../../../../public/assets/images/arrow-down.svg";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const FilterSearch: React.FC<Props> = props => {
  const { className, isProductSearch, onSubmit, redirect } = props;
  const { width, availWidth } = useWindowSize();
  const isMobile = width <= tablet;
  const { register, handleSubmit, setValue, watch, reset } =
    useForm<FilterSearchFormValues>(
      getDefaultFilterValues(filterDefault, isMobile)
    );
  const [step, setStep] = useState<Number>();
  const { push, replace, query } = useRouter();
  const { categoryId, tags } = query ?? {};

  const handleClickOutside = () => {
    setStep(undefined);
  };

  const ref = useOutsideClick(handleClickOutside);

  const onSubmitButton = (data: FilterSearchFormValues) => {
    const { age, size, sex, species } = data;
    const category = categories.find(
      category => category.name.toLowerCase() === species.toLocaleLowerCase()
    );
    const { id: categoryId } = category ?? {};
    const tagsObject = { age, sex, size };
    const tags = Object.values(tagsObject)
      .filter(e => e && e !== "Todos")
      .join(",")
      .toLowerCase();
    onSubmit?.(tags, categoryId);
    if (redirect || isProductSearch) {
      const queryParams: FilterSearchQueryParams = {};
      if (categoryId) queryParams.categoryId = categoryId;
      if (tags) queryParams.tags = tags;
      if (redirect) {
        push({ pathname: "/pets", query: { ...queryParams } });
        return;
      }
      if (isProductSearch) {
        replace({ pathname: "/pets", query: { ...queryParams } });
      }
    }
  };

  const overlayNode = (step: number) => {
    const [selectedOption] = options.filter(option => option.id === step);

    return (
      <DropdownOverlayStyled
        className="DropdownOverlay"
        onClick={e => e.stopPropagation()}
        ref={ref}
        width={availWidth}
      >
        <p className="DropdownOverlay__title">{selectedOption.title}</p>
        {selectedOption.filters.map((option, index) => (
          <div
            className="DropdownOverlay__item"
            key={`${selectedOption.name}-${option}`}
          >
            <input
              {...register(selectedOption.name)}
              type="radio"
              name={selectedOption.name}
              value={option}
              id={`${option}-${index}`}
              defaultChecked={index === 0}
            />
            <label
              htmlFor={`${option}-${index}`}
              className="DropdownOverlay__label"
            >
              {option}
            </label>
          </div>
        ))}
        <div className="DropdownOverlay__options">
          <Button
            className="DropdownOverlay__options__link"
            type="LINK"
            htmlType="button"
            onClick={() => {
              setValue(selectedOption.name, selectedOption.filters[0]);
              setStep(selectedOption.id + 1);
            }}
          >
            Omitir
          </Button>
          <Button
            color="black"
            htmlType="button"
            className="DropdownOverlay__options__confirm"
            onClick={() => setStep(selectedOption.id + 1)}
          >
            Confirmar
          </Button>
        </div>
      </DropdownOverlayStyled>
    );
  };

  useEffect(() => {
    if (!categoryId && !tags) return;
    let initialValues = { ...filterDefault };
    if (categoryId) {
      const selectedCategory = categories.find(
        category => category.id === +categoryId
      );
      if (!selectedCategory) return;
      initialValues = { ...initialValues, species: selectedCategory?.name };
    }
    const tagsArray = getProductTags(tags);
    if (tagsArray?.length) {
      tagsArray.map(tag => {
        options
          .filter((option, index) => index !== 0)
          .map(filterOption => {
            const matchedTag = filterOption.filters.filter(
              filterValue => filterValue.toLowerCase() === tag
            );
            if (matchedTag.length) {
              initialValues = {
                ...initialValues,
                [filterOption.name]: matchedTag[0]
              };
            }
            return filterOption;
          });
        return tag;
      });
    }
    reset(initialValues);
  }, [categoryId, tags, reset]);

  return (
    <Styles
      className={`FilterSearch ${className}`}
      isProductSearch={isProductSearch}
    >
      <form
        onSubmit={handleSubmit(onSubmitButton)}
        className="FilterSearch__form"
      >
        {options.map(option => {
          const isSelected =
            isMobile && watch(option.name.toLowerCase() as FilterFormValues);

          return (
            <div className="FilterSearch__item" key={option.id}>
              <p className="FilterSearch__title">{option.title}</p>
              <Dropdown
                overlay={overlayNode(option.id)}
                showDropdown={step === option.id}
                className="FilterSearch__dropdown"
                backdropConfig={{
                  opacity: 0.2,
                  color: "#000"
                }}
                position={isMobile ? "right" : "left"}
              >
                <Button
                  className="FilterSearch__button"
                  htmlType="button"
                  onClick={e => {
                    e.stopPropagation();
                    setStep(option.id);
                  }}
                >
                  <p
                    className={`FilterSearch__value${
                      isSelected ? "--selected" : ""
                    }`}
                  >
                    {watch(option.name.toLowerCase() as FilterFormValues) ||
                      (isMobile ? option.title : option.filters[0])}
                  </p>
                  <ArrowSVG />
                </Button>
              </Dropdown>
            </div>
          );
        })}
        <Button
          className="FilterSearch__submit"
          color={isProductSearch ? "primary-light" : "primary"}
          htmlType="submit"
          isLoading={false}
        >
          {isProductSearch ? (
            "Aplicar"
          ) : (
            <>
              <SearchVG /> Buscar
            </>
          )}
        </Button>
      </form>
    </Styles>
  );
};

FilterSearch.defaultProps = {
  className: ""
};

export default FilterSearch;
