import styled from "styled-components";

import { DiscoverTabsMenuItemStyledProps as Props } from "./DiscoverTabsMenuItem.types";

const DiscoverTabsMenuItemStyled = styled.div<Props>`
  & path {
    fill: ${props => (props.active ? "var(--palette-primary)" : "")};
  }

  && .TabsMenuItem__title {
    color: ${props =>
      props.active ? "var(--palette-primary)" : "var(--palette-black-s0-l10)"};
  }

  .DiscoverTabsMenuItem {
  }
`;

export default DiscoverTabsMenuItemStyled;
